import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Space } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RiArrowLeftLine } from 'react-icons/ri';
import { MdOutlineUnpublished } from 'react-icons/md';
import { useIntl } from 'react-intl';
import isObject from 'lodash/isObject';
import moment from 'moment';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Yup from '../../../../vendor/yup';
import urlPageFeatures from '../../../../urls/urlPageFeatures';
import urlPageFeatureDetails from '../../../../urls/urlPageFeatureDetails';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import getSourceFromUrl from '../../../../lib/getSourceFromUrl';
import isFieldRequired from '../../../Content/utils/isFieldRequired';
import handleResponse from '../../../../lib/handleResponse';
import createFileFromURL from '../../../../lib/createFileFromURL';
import getFeatureConfiguration from '../../utils/getFeatureConfiguration';
import getFeatureSourceTranslation from '../../utils/getFeatureSourceTranslation';
import ActionButton from '../../../../layout/components/action-button';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import FormEditFeature from '../../forms/FormEditFeature';
import FormAttachImagesToFeature from '../../forms/FormAttachImagesToFeature';
import transformErrors from '../../../../lib/transformErrors';
import useModifiedDynamicDataErrors from '../../../../app/hooks/useModifiedDynamicDataErrors';
import { mkValidationSchema } from '../../forms/FormEditFeature/FormEditFeature.const';
import { FEATURES_SOURCE_MAP } from '../PageFeatures/PageFeatures.const';
import { TYPE_IMAGE, TYPE_OBJECT } from '../../../Template builder/const/templates';
import { fieldTypeToValidationRule } from '../../../Content/forms/FormCreateDynamicNews/FormCreateDynamicNews.const';
import {
  useUpdateFeatureMutation,
  useGetSpecificFeatureQuery,
  useUnpublishFeatureMutation,
  useGetFeaturesUnavailableReleaseDatesQuery,
} from '../../api/featuresApiSlice';



let validation = {};

const PageFeaturesEdit = () => {
  const intl = useIntl();
  const language = useSelector(({ customise: { language } }) => language);
  const { pathname } = useLocation();
  const { featureId } = useParams();
  const [ errors, setFormErrors ] = useState({});
  const navigate = useNavigate();
  const featureSource = getSourceFromUrl(pathname);
  const featureConfig = getFeatureConfiguration(featureSource);

  const [ unpublishFeature, { isLoading: isUnpublishFeatureLoading } ] = useUnpublishFeatureMutation();
  const [ updateFeature, { isLoading: isUpdatingFeature } ] = useUpdateFeatureMutation();
  const { data: feature = {}, isFetching: isFeatureFetching } = useGetSpecificFeatureQuery({
    featureSource,
    id: featureId,
    include: 'images',
  });
  const [ initialValues, setInitialValues ] = useState({ preset_id: null, data: {}, target: {} });
  const {
    data: unavailableDates = [],
    isFetching: isUnavailableReleaseDatesFetching,
  } = useGetFeaturesUnavailableReleaseDatesQuery({ featureSource });

  const contentEnabled = featureConfig?.config?.content?.enabled ?? true;
  const allowUpdateInThePast = featureConfig?.config?.publish?.allow_update_past ?? true;
  const allowUnpublishPast = featureConfig?.config?.publish?.allow_unpublish_past ?? true;
  const allowUnpublish = allowUnpublishPast ?
    Boolean(feature?.is_published) :
    Boolean(feature?.is_published) && dayjs().isSameOrBefore(dayjs(feature?.released_at).subtract(-1, 'days'), 'day');

  const modifiedErrors = useModifiedDynamicDataErrors(
    feature?.template?.data.id,
    feature?.template?.data,
    errors,
    language,
  );

  const hasReleasedImages = feature?.images?.data.length > 0;
  const canPublish = [ FEATURES_SOURCE_MAP.EXTERNAL_PROMO ].includes(featureSource) || hasReleasedImages;

  const getDynamicData = (featureData) => {
    const data = {};
    const dataSchema = {};

    featureData?.template?.data?.fields?.data.filter((field) => field.type !== TYPE_OBJECT)
      .forEach(async (field) => {
        const featureField = featureData?.dynamic_data?.data?.find((item) => item.template_field_id === field.id);

        if (isFieldRequired(field?.validations?.data || [])) {
          dataSchema[field[`display_name_${language}`]] = Yup.object().shape({
            template_field_id: Yup.number().required(),
            ...fieldTypeToValidationRule[field.type],
          });
        }

        if (field.type === TYPE_IMAGE) {
          if (featureField?.value) {
            const fileName = featureField.value?.split('/').pop();
            const file = await createFileFromURL(featureField.value, fileName);

            data[field[`display_name_${language}`]] = {
              value: [ {
                uid: '-1',
                name: featureField?.value,
                thumbUrl: featureField?.value,
                status: 'done',
                originFileObj: file,
                url: featureField?.value,
              } ],
              template_field_id: field.id,
            };
          } else {
            data[field[`display_name_${language}`]] = {
              value: [],
              template_field_id: field.id,
            };
          }
        } else {
          data[field[`display_name_${language}`]] = {
            value: featureField?.value,
            template_field_id: field.id,
          };
        }

        setInitialValues((prevState) => ({
          ...prevState,
          data,
        }));
      });

    validation = mkValidationSchema(
      featureConfig,
      unavailableDates,
      {
        data: Yup.object().shape(dataSchema),
      },
      feature?.released_at,
      feature?.released_to,
      allowUpdateInThePast,
    );

    return data;
  };

  useEffect(() => {
    setInitialValues({
      released_at: feature?.released_at,
      released_to: feature?.released_to,
      is_in_featured: feature?.is_in_featured,
      use_utc_zero_time: feature?.use_utc_zero_time,
      template_id: feature?.template_id,
      preset_id: null,
      title: feature?.title,
      description: feature?.description,
      is_published: false,
      updated_at: feature?.updated_at,
      published_at: feature?.published_at,
      target: {
        min_version: feature?.target?.data?.min_version ?? '',
        max_version: feature?.target?.data?.max_version ?? '',
        platform: feature?.target?.data?.platform,
        countries: [ ...feature?.target?.data?.countries ?? [] ].sort(),
      },
      data: {},
    });

    getDynamicData(feature);
  }, [ isFeatureFetching ]);

  const handleUnpublish = () => {
    unpublishFeature({ featureSource, featureId })
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-unpublished-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-unpublish-failed', [], error));
  };

  const handleUpdateFeature = (data) => {
    updateFeature({ featureSource, featureId, values: data })
      .unwrap()
      .then((response) => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [
          () => setFormErrors({}),
          () => setInitialValues({
            released_at: response?.data?.released_at,
            released_to: response?.data?.released_to,
            is_in_featured: response?.data?.is_in_featured,
            use_utc_zero_time: response?.data?.use_utc_zero_time,
            template_id: response?.data?.template_id,
            title: response?.data?.title,
            description: response?.data?.description,
            is_published: false,
            updated_at: response?.data?.updated_at,
            published_at: response?.data?.published_at,
            data: getDynamicData(response?.data),
          }),
          () => {
            if (moment(response?.data?.updated_at).isSame(moment(response?.data?.published_at))) {
              navigate(urlPageFeatureDetails({ featureSource, featureId: response?.data?.id }));
            }
          },
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-update-failed',
        [ () => {
          if (isObject(error?.data?.errors)) {
            setFormErrors(transformErrors(error?.data?.errors));
          }
        } ],
        error,
      ));
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageFeatures({ featureSource })}>
              <IntlMessages id={getFeatureSourceTranslation(featureSource)} />
            </Link>
          }
          breadCrumbParent2={
            <Link to={urlPageFeatureDetails({ featureSource, featureId })}>
              {featureId}
            </Link>
          }
          breadCrumbActive={<IntlMessages id='ui-general-edit' />}
        />
        <Col md={12} span={24}>
          <Row
            gutter={[ 8, 8 ]}
            justify="end"
            align="middle"
          >
            {allowUnpublish && (
              <Col>
                <Space>
                  <ActionButton
                    loading={isUnpublishFeatureLoading}
                    title={intl.formatMessage({ id: 'ui-general-unpublish' })}
                    icon={<MdOutlineUnpublished className="hp-mr-8" size={18} />}
                    onClick={handleUnpublish}
                  />
                </Space>
              </Col>
            )}
            <Col>
              <Space>
                <Link to={urlPageFeatures({ featureSource })}>
                  <Button
                    type="primary"
                    className="hp-mr-sm-8"
                    ghost="true"
                    icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
                  >
                    <IntlMessages id='ui-general-back-to-list' />
                  </Button>
                </Link>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[ 32, 32 ]}>
        <FormEditFeature
          isLoading={isFeatureFetching || isUnpublishFeatureLoading || isUnavailableReleaseDatesFetching}
          initialErrors={modifiedErrors}
          initialValues={initialValues}
          images={feature?.images?.data ?? []}
          isSubmitting={isUpdatingFeature}
          onSubmit={handleUpdateFeature}
          validationSchema={validation}
          templateFields={feature?.template?.data?.fields?.data || []}
          isEnabledTemplateStatus={Boolean(feature?.template?.data?.is_active)}
          canPublish={canPublish}
          unavailableDates={unavailableDates}
        />
      </Row>
      {contentEnabled && (
        <Row gutter={[ 32, 32 ]} className="hp-mt-16">
          <FormAttachImagesToFeature featureSource={featureSource} />
        </Row>
      )}
    </>
  );
};

export default PageFeaturesEdit;
