import React, { useState } from 'react';
import { Col, Row, Space, Card, Table, Input } from 'antd';
import { Filter } from 'iconsax-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { RiAddLine } from 'react-icons/ri';
import { Search } from 'react-iconly';
import { useIntl } from 'react-intl';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import basePagination from '../../../../../const/pagination';
import urlPageAchievementsEdit from '../../../../../urls/urlPageAchievementsEdit';
import useToggle from '../../../../../app/hooks/useToggle';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import handleResponse from '../../../../../lib/handleResponse';
import applyFilter from '../../../../../lib/applyFilter';
import resetFilter from '../../../../../lib/resetFilter';
import { getAchievementsTableColumns } from './PageAchievements.const';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import TotalLabel from '../../../../../components/TotalLabel';
import Sidebar from '../../../../../components/Sidebar';
import FormAchievementsFilter from '../../../forms/FormAchievementsFilter';
import FormCreateEditAchievement from '../../../forms/FormCreateEditAchievement';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import {
  useCreateAchievementMutation,
  useDeleteAchievementMutation,
  useGetAchievementsQuery,
} from '../../../api/achievementsApiSlice';



const PageAchievements = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ achievementInitialErrors, setAchievementInitialErrors ] = useState({});
  const [ filterOpen, toggleFilterSidebar ] = useToggle();
  const [ isOpenCreateAchievementSidebar, toggleCreateAchievementSidebar ] = useToggle();

  const searchFields = [ 'name' ];
  const searchFilterFields = [ 'id', 'code', 'name', 'created_from', 'created_to' ];

  const {
    pagination,
    searchParams,
    initFilterValues,
    setInitFilterValues,
    search,
    setSearchTerm,
    resetIsManuallyTriggered,
    setResetManuallyTriggered,
    handleChangeTableParams,
  } = useQueryParams({ searchFields, searchFilterFields });

  const { data: {
    data: achievements = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} },
  isFetching,
  error: formErrors = {},
  } = useGetAchievementsQuery({ queryParams: `${searchParams.toString()}` });

  const [ deleteAchievement, { isLoading: isDeletingAchievement } ] = useDeleteAchievementMutation();
  const [ createAchievement, { isLoading: isCreatingAchievement } ] = useCreateAchievementMutation();

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setResetManuallyTriggered(false);
  };

  const handleEdit = (id) => {
    navigate(urlPageAchievementsEdit({ id }));
  };

  const handleCreate = (data, resetForm) => {
    createAchievement({ payload: data })
      .unwrap()
      .then((response) => {
        handleResponse(
          'success',
          intl,
          'ui-general-created-successfully',
          [
            toggleCreateAchievementSidebar,
            resetForm,
            () => navigate(urlPageAchievementsEdit({ id: response?.data?.id })),
          ],
        );
      })
      .catch((error) => {
        handleResponse(
          'error',
          intl,
          'ui-general-create-failed',
          [ () => setAchievementInitialErrors(error?.data?.errors || {}) ],
          error,
        );
      });
  };

  const handleDelete = (id) => {
    deleteAchievement(id)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const isLoading = isFetching || isDeletingAchievement || isCreatingAchievement;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='achievements-breadcrumbs' />} />

        <Col>
          <Space>
            <TotalLabel total={dataPagination.total ?? 0} />
            <Col>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'ui-general-search' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={search}
                onChange={handleSearch}
              />
            </Col>
            <ActionButton
              onClick={toggleCreateAchievementSidebar}
              icon={<RiAddLine className="btn-icon-mr-1" />}
              title={<IntlMessages id='ui-general-create' />}
            />
            <ActionButton
              title=""
              isActive={!isEmpty(initFilterValues)}
              icon={<Filter size={18} />}
              onClick={toggleFilterSidebar}
            />
          </Space>
        </Col>

        <Sidebar
          title={<IntlMessages id='achievements-filter-sidebar' />}
          visible={filterOpen}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormAchievementsFilter
            isSubmitting={false}
            formErrors={formErrors}
            initialValues={initFilterValues}
            onCancel={() => {
              setResetManuallyTriggered(true);

              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
                resetIsManuallyTriggered,
                setSearchTerm,
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                setSearchField: setSearchTerm,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>

        <Sidebar
          title={<IntlMessages id='achievements-create-sidebar' />}
          visible={isOpenCreateAchievementSidebar}
          toggleSidebar={toggleCreateAchievementSidebar}
        >
          <FormCreateEditAchievement
            initialValues={{}}
            initialErrors={achievementInitialErrors}
            onSubmit={handleCreate}
            onCancel={() => {}}
            isSubmitting={isCreatingAchievement}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={makeTableLoadingObject(isLoading)}
              rowKey="id"
              columns={getAchievementsTableColumns(handleEdit, handleDelete)}
              dataSource={achievements}
              onChange={handleChangeTableParams}
              pagination={{
                ...basePagination,
                current: pagination.page,
                pageSize: pagination.limit,
                total: dataPagination?.total,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageAchievements;
