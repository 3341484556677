import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const AchievementsApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Achievements' ],
  endpoints: (builder) => {
    return ({
      getAchievements: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/achievements${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            pagination: response.meta.pagination,
          };
        },
        transformErrorResponse: (response) => response?.data?.errors,
        providesTags: [ 'Achievements' ],
        keepUnusedDataFor: 1,
      }),
      getAchievement: builder.query({
        query: (id) => {
          return ({ url: `/${Config.VITE_HC_API_VERSION}/achievements/${id}` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
          };
        },
        providesTags: [ 'Achievement' ],
      }),
      createAchievement: builder.mutation({
        query: ({ payload }) => {
          return ({
            url: `/${Config.VITE_HC_API_VERSION}/achievements`,
            method: 'POST',
            body: payload,
          });
        },
        invalidatesTags: () => [ 'Achievements' ],
      }),
      updateAchievement: builder.mutation({
        query: ({ id, payload }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/achievements/${id}`,
          method: 'PUT',
          body: payload,
        }),
        invalidatesTags: () => [ 'Achievements', 'Achievement' ],
      }),
      deleteAchievement: builder.mutation({
        query: (id) => ({
          url: `/${Config.VITE_HC_API_VERSION}/achievements/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: () => [ 'Achievements' ],
      }),
    });
  },
});

export const {
  useGetAchievementsQuery,
  useGetAchievementQuery,
  useDeleteAchievementMutation,
  useCreateAchievementMutation,
  useUpdateAchievementMutation,
} = AchievementsApiSlice;
