import React from 'react';
import { Card, Col, Popconfirm, Row, Table } from 'antd';
import { RiErrorWarningLine } from 'react-icons/ri';
import { useIntl } from 'react-intl';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../../const/permissions';
import { PrivateComponent } from '../../../../../components/HasRights/HasRights';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import useElementSize from '../../../../../app/hooks/useElementSize';
import handleResponse from '../../../../../lib/handleResponse';
import ButtonDanger from '../../../../../components/ButtonDanger';
import {
  RESET_TYPE_DAILY,
  RESET_TYPE_FEED,
  getStaticContentResetLogsListTableColumns,
} from './PageStaticContentResetLogs.const';
import { useGetStaticContentResetLogsQuery, useResetStaticContentMutation } from '../../../api/staticContentResetApiSlice';



const PageStaticContentResetLogs = () => {
  const intl = useIntl();
  const [ elementRef, size ] = useElementSize();

  const [ resetStaticContent, { isLoading: isResetStaticContent } ] = useResetStaticContentMutation();

  const { data: {
    data: logs = [],
  } = { data: [] }, isFetching: isLogsFetching } = useGetStaticContentResetLogsQuery();

  const handleReset = (type) => {
    resetStaticContent({ type })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'static-content-reset-successfully',
      ))
      .catch((error) => {
        handleResponse(
          'error',
          intl,
          'static-content-reset-failed',
          [],
          error,
        );
      });
  };

  const isLoading = isLogsFetching || isResetStaticContent;

  return (
    <Row gutter={32}>
      <Col className="hp-mb-32" span={24}>
        <Row
          gutter={[ 32, 32 ]}
          justify="space-between"
          className="hp-print-none"
          align="middle"
        >
          <BreadCrumbs breadCrumbActive={<IntlMessages id='static-content-reset-logs-breadcrumbs' />} />

          <PrivateComponent allowedPermissions={[ Permissions.OTHER.STATIC_CONTENT_RESET.RESET ]}>
            <Col md={16} span={24}>
              <Row
                gutter={[ 16, 16 ]}
                justify="end"
                align="middle"
              >
                <Col>
                  <Popconfirm
                    icon={<RiErrorWarningLine className="remix-icon hp-text-color-danger-1" />}
                    title={<IntlMessages id='static-content-reset-logs-btn-reset-feed-confirm' />}
                    onConfirm={() => handleReset(RESET_TYPE_FEED)}
                    okButtonProps={{ danger: true }}
                    okText={<IntlMessages id='ui-general-ok' />}
                    cancelText={<IntlMessages id='ui-general-cancel' />}
                  >
                    <ButtonDanger
                      disabled={isLoading}
                      className='btn-danger'
                      text={<IntlMessages id='static-content-reset-logs-btn-reset-feed' />}
                    />
                  </Popconfirm>
                </Col>
                <Col>
                  <Popconfirm
                    icon={<RiErrorWarningLine className="remix-icon hp-text-color-danger-1" />}
                    title={<IntlMessages id='static-content-reset-logs-btn-reset-daily-confirm' />}
                    onConfirm={() => handleReset(RESET_TYPE_DAILY)}
                    okButtonProps={{ danger: true }}
                    okText={<IntlMessages id='ui-general-ok' />}
                    cancelText={<IntlMessages id='ui-general-cancel' />}
                  >
                    <ButtonDanger
                      disabled={isLoading}
                      className='btn-danger'
                      text={<IntlMessages id='static-content-reset-logs-btn-reset-daily' />}
                    />
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          </PrivateComponent>
        </Row>
      </Col>

      <Col span={24}>
        <Card className="hp-border-color-black-40 hp-card-6">
          <div ref={elementRef}>
            <Table
              sticky
              loading={makeTableLoadingObject(isLoading)}
              rowKey="id"
              columns={getStaticContentResetLogsListTableColumns(size)}
              dataSource={logs}
              pagination={false}
            />
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default PageStaticContentResetLogs;
