import React, { useMemo } from 'react';
import { Badge, Button, Col, Popconfirm, Row, Tooltip } from 'antd';
import { RiBarChartFill, RiErrorWarningLine, RiQuestionLine } from 'react-icons/ri';
import { IoMdImages } from 'react-icons/io';
import { PiWaveSineDuotone } from 'react-icons/pi';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import appColors from '../../../../const/colors';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../const/permissions';
import { DATE_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import { PrivateComponent } from '../../../../components/HasRights/HasRights';
import { usePlanningBoardImagesContext } from '../../contexts/PlanningImagesContext';
import { getMedianOrAvgColor } from '../FeedsPlanningBoardCalendar/FeedsPlanningBoardCalendarCells/FeedsPlanningBoardCalendarCells.const';
import handleResponse from '../../../../lib/handleResponse';
import { useUnpublishFeedsPlanningBoardMutation } from '../../api/feedsPlanningApiSlice';



const PlanningBoardDetailsHeader = ({
  isEditing = false,
  handleCancelChanges = () => {},
  isLoadingPlanningDetails = false,
}) => {
  const intl = useIntl();
  const { date } = useParams();

  const { items: planningBoardImages } = usePlanningBoardImagesContext();

  const unpublishDeadlineTime = dayjs.utc().add(12, 'hour');
  const selectedDateTime = dayjs.utc(date);
  const canClearPlanningBoard = unpublishDeadlineTime.isBefore(selectedDateTime) && !isEmpty(planningBoardImages);
  const showClearPlanningBoardTooltip = unpublishDeadlineTime.isSameOrAfter(selectedDateTime) && !isEmpty(planningBoardImages);

  const [ clearPlanningBoard, { isLoading: isClearingPlanningBoard } ] = useUnpublishFeedsPlanningBoardMutation();

  const { medianTaps, averageTaps, imagesCount } = useMemo(() => {
    if (!planningBoardImages || planningBoardImages.length === 0) {
      return { medianTaps: 0, averageTaps: 0, imagesCount: 0 };
    }

    const taps = planningBoardImages?.map((item) => item.data.data[0].taps);

    const average = Math.round(taps.reduce((sum, tap) => sum + tap, 0) / taps.length);

    taps.sort((first, second) => first - second);
    const mid = Math.floor(taps.length / 2);
    const median = Math.round(taps.length % 2 !== 0 ? taps[mid] : (taps[mid - 1] + taps[mid]) / 2);

    return { medianTaps: median, averageTaps: average, imagesCount: planningBoardImages.length };
  }, [ planningBoardImages ]);

  const handleClearConfirm = () => {
    clearPlanningBoard({ date: dayjs(date).format(DATE_FORMAT) })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [ () => handleCancelChanges() ],
      ))
      .catch((error) => handleResponse('error', intl, 'ui-general-update-failed', [], error));
  };

  return (
    <Row
      gutter={[ 8, 8 ]}
      justify='space-between'
      className='hp-align-items-center'
    >
      <Col span={24}>
        <Row justify='start' className='hp-align-items-center'>
          <IntlMessages id="ui-general-planning-images" />
          <Tooltip
            placement="top"
            title={
              <>
                <b><IntlMessages id="planning-board-status-info" /></b>
                <br />
                <Badge color='volcano' />
                <IntlMessages id="planning-board-status-info-red" />
                <br />
                <br />
                <Badge color='gold' />
                <IntlMessages id="planning-board-status-info-yellow" />
                <br />
                <br />
                <Badge color='lime' />
                <IntlMessages id="planning-board-status-info-green" />
              </>
            }
          >
            <RiQuestionLine
              size={18}
              fill='blue'
              className='hp-ml-8 hp-cursor-pointer'
            />
          </Tooltip>
        </Row>
      </Col>

      <Col span={24}>
        <Row
          justify='space-between'
          gutter={[ 16, 16 ]}
          className='hp-align-items-center'
          style={{ fontSize: 14 }}
        >
          <Col>
            {!isLoadingPlanningDetails && (
              <Row
                justify='start'
                gutter={16}
                className='hp-align-items-center'
                style={{ fontSize: 14 }}
              >
                <Col>
                  <Row className='hp-align-items-center'>
                    <IoMdImages size={26} className='hp-text-color-black-80' />
                  </Row>
                </Col>
                <Col style={{ color: imagesCount < 15 ? appColors.error : '', fontSize: 18, paddingLeft: 0 }}>
                  <strong>{imagesCount}</strong>
                </Col>
                <Col className='hp-text-color-black-80 hp-ml-8'>
                  <Tooltip placement="top" title={<IntlMessages id="feeds-calendar-average" />}>
                    <Row className='hp-align-items-center'>
                      <PiWaveSineDuotone size='18' className='hp-mr-4' />
                      <Col style={getMedianOrAvgColor(averageTaps)}>
                        {averageTaps ?? EMPTY_VALUE_PLACEHOLDER}
                      </Col>
                    </Row>
                  </Tooltip>
                </Col>
                <Col className='hp-text-color-black-80'>
                  <Tooltip placement="top" title={<IntlMessages id="feeds-calendar-median" />}>
                    <Row className='hp-align-items-center'>
                      <RiBarChartFill size='18' className='hp-mr-4' />
                      <Col style={getMedianOrAvgColor(medianTaps)}>
                        {medianTaps ?? EMPTY_VALUE_PLACEHOLDER}
                      </Col>
                    </Row>
                  </Tooltip>
                </Col>
              </Row>
            )}
          </Col>

          <Col>
            {isEditing && (
              <PrivateComponent
                isMultipleAllowance
                allowedPermissions={[
                  Permissions.CONTENT.FEEDS.PLANNING.BOARD.DETAILS,
                  Permissions.CONTENT.FEEDS.PLANNING.BOARD.UNPUBLISH,
                ]}
              >
                <Popconfirm
                  title={<IntlMessages id="planning-board-clear-confirm-message" />}
                  placement="top"
                  disabled={!canClearPlanningBoard}
                  onConfirm={handleClearConfirm}
                  okText={<IntlMessages id="ui-general-yes" />}
                  cancelText={<IntlMessages id="ui-general-no" />}
                  icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                  okButtonProps={{ danger: true }}
                >
                  <Tooltip title={showClearPlanningBoardTooltip ? <IntlMessages id="planning-board-disabled-clear-hint" /> : ''}>
                    <Button
                      size="small"
                      danger
                      loading={isClearingPlanningBoard}
                      className={canClearPlanningBoard ? 'feed-modal__button--danger' : ''}
                      disabled={!canClearPlanningBoard}
                    >
                      <IntlMessages id="planning-board-clear" />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              </PrivateComponent>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

PlanningBoardDetailsHeader.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  handleCancelChanges: PropTypes.func.isRequired,
  isLoadingPlanningDetails: PropTypes.bool.isRequired,
};

export default PlanningBoardDetailsHeader;
