import React, { useState } from 'react';
import { Card, Col, Input, Modal, Row, Table } from 'antd';
import { Search } from 'react-iconly';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { RiAddLine, RiCloseFill } from 'react-icons/ri';
import isObject from 'lodash/isObject';
import { useNavigate } from 'react-router-dom';
import handleResponse from '../../../../../lib/handleResponse';
import FormCreateEditUser from '../../../forms/FormCreateEditUser';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import Spinner from '../../../../../components/Spinner';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import { getUsersListTableColumns } from './PageUsers.const';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import { useCreateUserMutation, useDeleteUserMutation, useGetUsersQuery } from '../../../api/usersApiSlice';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import useToggle from '../../../../../app/hooks/useToggle';
import { useApp } from '../../../../../app/context/AppContext';
import useElementSize from '../../../../../app/hooks/useElementSize';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import urlPageRBACUserDetails from '../../../../../urls/urlPageRBACUserDetails';
import basePagination from '../../../../../const/pagination';



const PageUsers = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { userId } = useApp();
  const [ elementRef, size ] = useElementSize();
  const [ isActiveUserModal, toggleCreateUserModal ] = useToggle();
  const [ createUserErrors, setCreateUserErrors ] = useState({});

  const {
    pagination,
    search,
    setSearchTerm,
    handleChangeTableParams,
    searchParams,
  } = useQueryParams({ searchFields: [ 'name', 'email' ] });

  const { data: {
    data: users = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} },
  isFetching: isLoading,
  } = useGetUsersQuery({ queryParams: searchParams.toString() }, { skip: searchParams.get('is_active') });

  const [ createUser, { isLoading: isCreateUserLoading } ] = useCreateUserMutation();
  const [ deleteUser, { isLoading: isDeleteUserLoading } ] = useDeleteUserMutation();

  const handleDeleteUser = (id) => {
    deleteUser(id).unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const handleSubmitCreateUserForm = async (values) => {
    createUser(values)
      .unwrap()
      .then((response) => handleResponse(
        'success',
        intl,
        'ui-general-created-successfully',
        [
          toggleCreateUserModal,
          () => {
            if (response?.data?.id) {
              navigate(urlPageRBACUserDetails({ userId: response.data.id }));
            }
          },
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-create-failed',
        [ () => {
          if (isObject(error?.data?.errors)) {
            setCreateUserErrors(error.data.errors);
          }
        } ],
        error,
      ));
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const onCancelCreateUserModal = () => {
    toggleCreateUserModal();
    setCreateUserErrors({});
  };

  return (
    <>
      <Modal
        title={<IntlMessages id="users-create-user-title" />}
        width={416}
        centered
        destroyOnClose
        visible={isActiveUserModal}
        onCancel={onCancelCreateUserModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <Spinner spinning={isCreateUserLoading}>
          <FormCreateEditUser
            initialValues={{}}
            formErrors={createUserErrors}
            onSubmit={handleSubmitCreateUserForm}
            onCancel={onCancelCreateUserModal}
            isSubmitting={isCreateUserLoading}
          />
        </Spinner>
      </Modal>

      <Row gutter={32}>
        <Col className="hp-mb-32" span={24}>
          <Row
            gutter={[ 32, 32 ]}
            justify="space-between"
            className="hp-print-none"
          >
            <BreadCrumbs breadCrumbActive={<IntlMessages id='users-breadcrumbs' />} />

            <Col md={15} span={24}>
              <Row justify="end" align="middle" gutter={[ 16, 16 ]}>
                <Col md={12} span={24}>
                  <Input
                    allowClear={!isEmpty(search)}
                    placeholder={intl.formatMessage({ id: 'users-search-by' })}
                    prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                    value={search}
                    onChange={handleSearch}
                  />
                </Col>

                {HasRights([ Permissions.ADMINISTRATION.USERS.USERS_LIST.CREATE_USER ]) && (
                  <Col>
                    <ActionButton
                      title={<IntlMessages id="users-create-user-title" />}
                      icon={<RiAddLine className="btn-icon-mr-1" />}
                      onClick={toggleCreateUserModal}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <div ref={elementRef}>
              <Table
                sticky
                scroll={{ x: 860 }}
                rowKey="id"
                dataSource={users}
                loading={makeTableLoadingObject(isDeleteUserLoading || isLoading)}
                columns={getUsersListTableColumns(handleDeleteUser, userId, size)}
                onChange={handleChangeTableParams}
                pagination={{
                  ...basePagination,
                  current: pagination.page,
                  pageSize: pagination.limit,
                  total: dataPagination?.total,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default PageUsers;
