import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const FeaturesApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Features', 'FeaturesPriority', 'FeaturesItem' ],
  endpoints: (builder) => {
    return ({
      getFeatures: builder.query({
        query: (params) => {
          const { featureSource, queryParams } = params;
          const queryData = makeQueryParams(queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/features/${featureSource}${queryData}` });
        },
        transformResponse: (response) => {
          return {
            data: response?.data,
            pagination: response?.meta?.pagination,
          };
        },
        transformErrorResponse: (response) => response?.data?.errors,
        providesTags: [ 'Features' ],
        keepUnusedDataFor: 1,
      }),
      getSpecificFeature: builder.query({
        query: (params) => {
          const baseUrl = `/${Config.VITE_HC_API_VERSION}/features/${params.featureSource}/${params.id}`;
          const includeParam = params?.include ? `?include=${params.include}` : '';

          return { url: baseUrl + includeParam };
        },
        transformResponse: (response) => response.data,
        providesTags: () => [ { type: 'FeaturesItem' } ],
      }),
      createFeature: builder.mutation({
        query: ({ featureSource, values }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/features/${featureSource}`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'Features', 'FeaturesPriority', 'Templates', 'Template' ],
      }),
      updateFeature: builder.mutation({
        query: ({ featureSource, featureId, values }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/features/${featureSource}/${featureId}`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'Features', 'FeaturesItem', 'FeaturesPriority', 'Template', 'Feeds' ],
      }),
      deleteFeature: builder.mutation({
        query: ({ featureSource, id }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/features/${featureSource}/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: () => [ 'Features', 'FeaturesPriority', 'Template' ],
      }),
      attachImageToFeature: builder.mutation({
        query: ({ featureSource, featureId, payload }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/features/${featureSource}/${featureId}/images`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: () => [ 'Features', 'FeaturesItem', 'Images', 'FeaturesPriority' ],
      }),
      unpublishFeature: builder.mutation({
        query: ({ featureSource, featureId }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/features/${featureSource}/${featureId}/unpublish`,
          method: 'POST',
        }),
        invalidatesTags: () => [ 'Features', 'FeaturesItem', 'FeaturesPriority' ],
      }),
      getFeaturesUnavailableReleaseDates: builder.query({
        query: ({ featureSource }) => {
          return ({ url: `/${Config.VITE_HC_API_VERSION}/features/${featureSource}/dates/unavailable-release-dates` });
        },
        transformResponse: (response) => {
          return response;
        },
        providesTags: [ 'Features' ],
      }),
      getFeaturesPriority: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/feature/settings/priorities${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response?.data,
          };
        },
        providesTags: [ 'FeaturesPriority' ],
      }),
      updateFeaturesPriority: builder.mutation({
        query: (values) => ({
          url: `/${Config.VITE_HC_API_VERSION}/feature/settings/priorities`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'FeaturesPriority' ],
      }),
    });
  },
});

export const {
  useGetFeaturesQuery,
  useGetSpecificFeatureQuery,
  useCreateFeatureMutation,
  useUpdateFeatureMutation,
  useAttachImageToFeatureMutation,
  useDeleteFeatureMutation,
  useUnpublishFeatureMutation,
  useGetFeaturesUnavailableReleaseDatesQuery,
  useGetFeaturesPriorityQuery,
  useUpdateFeaturesPriorityMutation,
} = FeaturesApiSlice;
